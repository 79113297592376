import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import styles from './commonBannerWrap.module.scss'
const CommonBannerWrap = ({ children, className }) => {
  const fullscreenState = useSelector((state) => state.system.fullscreenState)
  const WidthHeightRatio = window.innerWidth / window.innerHeight
  const showFullscreen = fullscreenState && WidthHeightRatio <= 2.6504065040650406
  return <div
    className={classNames([
      styles.container,
      className,
      showFullscreen ? styles.fullscreenState : '',
    ])}
    style={{
      height: showFullscreen
        ? `${window.getZoomHeight(WidthHeightRatio < 2.1 ? 0.75 : 1)}px`
        : 'auto',
    }}
  >
    <div className={styles.emptyBlock}></div>
    {children}
  </div>
}

export default CommonBannerWrap
