import produce from 'immer'
import * as types from './constant'

const INIT_STATE = {
  cloudGames: [],
  junsGames: [],
  categorys: [],
  categoryMap: {}
}
export default produce((state, { type, payload = [] }) => {
  switch (type) {
    case types.FETCH_JUNS_GAME_APPS:
      state.junsGames = payload
      break
    case types.FETCH_CLOUDGAMES_APPS:
      const categorys = [...new Set(payload.map(app => app.genre).filter(Boolean))]
      const sortGames = payload.sort((a, b) => {
        const flag = a.gameRank - b.gameRank
        if (flag > 0) {
          return -1
        }
        if (flag < 0) {
          return 1
        }
        return 0
      })
      state.cloudGames = sortGames
      state.categorys = categorys
      state.categoryMap = categorys.reduce((map, category) => {
        map[category] = sortGames.filter(app => app.genre === category)
        return map
      }, {})
      break
    default: {
    }
  }
}, INIT_STATE)
