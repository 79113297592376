import React, { useRef } from 'react'
// import { useSelector } from 'react-redux'
// import { useDebounceFn } from 'ahooks'
import HomeContainer from './homeContainer/Index'
import GameCenterContainer from './gameCenterContainer/Index'
// import useScrollIntoView from './useScrollIntoView'
import useBackgroundImageScroll from '@/hook/useBackgroundImageScroll'
import useScrollMemory from '@/hook/useScrollMemory'
import useScrollBarActive from '@/hook/useScrollBarActive'
import { useGridBaseCount } from '@/hook/useViewport'
// import Utils from '@/Utils'
// import { qwebApi } from '@/apis/qwebApi'


import './index.scss'

const HomeGameCenter = () => {
  const containerRef = useRef(null)
  const homeContainerRef = useRef(null)
  const gameCenterContainerRef = useRef(null)
  // const prevScrollStatus = useRef(null)
  // const pageLocation = useSelector((state) => state.system.location)

  // useScrollIntoView({
  //   home: homeContainerRef,
  //   gameCenter: gameCenterContainerRef
  // }, {
  //   key: pageLocation || 'home'
  // })

  const gridCount = useGridBaseCount()

  useBackgroundImageScroll(containerRef)
  useScrollMemory(containerRef, { key: 'home-scroll-memory' })

  // const onScroll = useDebounceFn(() => {
  //   const gameCenterContainer = gameCenterContainerRef.current
  //   const { top } = gameCenterContainer.getBoundingClientRect()
  //   const clientHeight = document.documentElement.clientHeight
  //   const gameCenterVisible = top < clientHeight
  //   if (prevScrollStatus.current !== gameCenterVisible) {
  //     const path = gameCenterVisible ? 'gameCenter' : 'home'
  //     qwebApi({
  //       event: 'switchTabTo',
  //       data: { name: path }
  //     })
  //     prevScrollStatus.current = gameCenterVisible
  //   }
  // }, { wait: 100 })
  useScrollBarActive(containerRef)
  return <div className='home-game-center' ref={containerRef}>
    <HomeContainer className="breakScreen" ref={homeContainerRef} gridCount={gridCount} />
    <GameCenterContainer className="breakScreen margin-bottom-30" ref={gameCenterContainerRef} gridCount={gridCount} />
  </div>
}

export default HomeGameCenter
