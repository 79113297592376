import { getFormDataBySearch } from '../tool'
import fetch from '../fetch'
import crypto from 'crypto'
import store from '@/store'

window.__CLIENT_CONFIG__ = window.__CLIENT_CONFIG__ || {
  systemConfig: {},
  x_version: '',
  commonConfig: {},
}
/**
 * Fetch rotation-banner request
 * @returns Promise
 */
export const getRotationAppsApi = () => {
  const url = `${window.__HOST_CONFIG__.baseHost}/appcenter/game_center_rotation`
  return fetch({
    method: 'post',
    url,
    data: getFormDataBySearch({
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    }),
  })
}

/**
 * Fetch cloud game request
 * @returns Promise
 */
export const getCloudGameApps = ({ showDisconnect = false, locale, limit = 99, tags }) => {
  const url = `${window.__HOST_CONFIG__.cloudHost}/api/apps/v1/launcher/list`
  return fetch(
    {
      method: 'get',
      url,
      params: { limit, locale, tags, ...window.__CLIENT_CONFIG__.commonConfig },
      headers: {
        Authorization: 'Bearer Zfl!fJ_0hd*&AjxT94VAL(sXv/$RynX3OxgNffnw%Mr?OPxx%k',
      },
      showDisconnect,
    },
    (response) => {
      if (response.data) {
        return response.data.payload
      }
      return false
    }
  )
}

/**
 * Fetch popular cloud game request
 * @returns Promise
 */
export const getPopularCloudGameApps = () => {
  const url = `${window.__HOST_CONFIG__.cloudHost}/api/apps/v1/popular/apps/list`
  return fetch({
    method: 'get',
    url,
    params: {
      ...window.__CLIENT_CONFIG__.commonConfig,
      utm_source: 'now.gg-partner',
      utm_medium: 'client',
      utm_campaign: 'BlueStacksX',
    },
    noValidate: true,
  })
}

/**
 * Fetch game-center-home request
 * @returns Promise
 */
export const getHomeAppsApi = () => {
  const url = `${window.__HOST_CONFIG__.baseHost}/appcenter/game_center_home`
  return fetch({
    method: 'post',
    url,
    data: getFormDataBySearch({
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    }),
    showDisconnect: true,
  })
}

/**
 * Fetch topic detail info
 * @param {Object} param0 topic id
 * @returns Promise
 */
export const getTopicInfo = ({ topic_id }) => {
  const url = `${window.__HOST_CONFIG__.baseHost}/appcenter/app_center_topic_data`
  return fetch({
    method: 'get',
    url: url,
    params: {
      topic_id,
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    },
    noValidate: true,
  })
}

/**
 * Fetch search apps(deprecated,use launcher/search/v1)
 * @returns Promise
 */
export const getSearchAppsApi = (query, showDisconnect) => {
  const url = `${window.__HOST_CONFIG__.baseHost}/appcenter/search/v3`
  return fetch({
    method: 'post',
    url,
    data: getFormDataBySearch({
      query,
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    }),
    noValidate: true,
    showDisconnect,
  })
}

/**
 * Fetch search apps
 * @returns Promise
 */
export const getSearchAppsApi_v4 = (query, config = {}) => {
  // const url = `${window.__HOST_CONFIG__.baseHost}/appcenter/search/v4`
  const url = `${window.__HOST_CONFIG__.baseHost}/launcher/search/v1`
  const userState = store.getState().user.userInfo
  const { player_guid = '', player_version = '' } = window.__CLIENT_CONFIG__.player_info
  return fetch({
    method: 'get',
    url,
    params: {
      query,
      search_client: 'launcher',
      user_id: userState?.userid,
      user_email: userState?.email,
      player_guid,
      player_version,
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    },
    noValidate: true,
    ...config,
  })
}

/**
 * Fetch side_panel request
 * @returns Promise
 */
export const getSidepanelAppsApi = () => {
  const url = `${window.__HOST_CONFIG__.baseHost}/bs3/side_panel`
  return fetch({
    method: 'post',
    url,
    data: getFormDataBySearch({
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    }),
    noValidate: true,
  })
}

/**
 * Fetch feature apps request
 * @returns Promise
 */
export const getFeatureAppsApi = (extraData = {}) => {
  const url = `${window.__HOST_CONFIG__.baseHost}/bs4/get_cpi_apps_for_quest`
  return fetch({
    method: 'get',
    url,
    params: {
      ...extraData,
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    },
    noValidate: true,
  })
}

/**
 * Check the country of the ip request
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export const getIpCountry = (params = {}) => {
  // const url = `${localServerHost}/get_ip_local`
  const url = `${window.__HOST_CONFIG__.baseHost}/api/getcountryforip`
  return fetch({
    method: 'get',
    url,
    params,
    noValidate: true,
  })
}

/**
 * Fetch public playerlist(useless)
 * @param page
 * @param geo
 * @param limit
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export const getPlayerListPublic = (page = 1, geo = '', limit = 3, params = {}) => {
  const url = `${window.__HOST_CONFIG__.ssoHost}/api/v1/public/users/influencer`
  const client_id = 'ab06713e-290d-4e9d-ba75-911fbe66e699' //'eb70fbf7-c8d5-4b5e-99b3-13337eafbcfe'
  const timestamp = Date.now() - 5000
  const token_str = client_id + '_' + timestamp
  const x_secret_token = crypto.createHash('sha256').update(token_str).digest('hex')
  return fetch({
    method: 'get',
    headers: {
      'x-request-client': client_id,
      'x-request-timestamp': timestamp,
      'x-secret-token': x_secret_token,
    },
    url,
    params: {
      page,
      geo,
      limit,
      ...params,
    },
    noValidate: true,
  })
}

/**
 * Set header(useless)
 * @param email
 * @param header
 * @returns {*}
 */
export const getSecretToken = (token, header) => {
  header['Authorization'] = 'Bearer ' + token

  return header
}

/**
 * Fetch playerlist by user(useless)
 * @param email
 * @param page
 * @param geo
 * @param limit
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export const getPlayerListPrivate = (token, page = 1, geo = '', limit = 3, params = {}) => {
  const headers = getSecretToken(token, {})
  const url = `${window.__HOST_CONFIG__.ssoHost}/api/v1/users/influencer`
  return fetch({
    headers: headers,
    method: 'get',
    url,
    params: {
      page,
      geo,
      limit,
      ...params,
    },
    noValidate: true,
  })
}

/**
 * Set PlayerList Like Or Dislike
 * @param email
 * @param itemId
 * @param like
 * @returns {Promise | Promise<unknown>}
 */
export const setPlayerLikeDislike = (token, itemId, like) => {
  const headers = getSecretToken(token, {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })
  const url = `${window.__HOST_CONFIG__.ssoHost}/api/v1/users/items/${itemId}`
  const payload = {
    action: like,
  }

  return fetch({
    headers: headers,
    method: 'PATCH',
    url,
    data: payload,
    noValidate: true,
  })
}

/**
 * Fetch app info request
 * @param pkg
 * @param country
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export const getAppInfoFromCloud = (params = {}) => {
  const url = `${window.__HOST_CONFIG__.baseHost}/app/info`
  return fetch({
    method: 'get',
    url,
    params: {
      ...params,
      ...window.__CLIENT_CONFIG__.commonConfig,
    },
    noValidate: true,
  })
}

/**
 * Fetch apk workflow
 * @param {Object} packageName
 * @returns Promise
 */
export const getApkWorkflow = ({ packageName }) => {
  const url = `${window.__HOST_CONFIG__.baseHost}/app_player/get_apk_download_source`
  return fetch({
    method: 'post',
    url: url,
    params: {
      app_package: packageName,
      app_install_type: 'install',
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    },
    noValidate: true,
  })
}

/**
 * Fetch preference by packagenames
 * @param {Object} packageName 包名
 * @returns Promise
 */
export const getGamePlayPreference = ({ appPkgs, country }) => {
  const url = `${window.__HOST_CONFIG__.baseHost}/app_player/get_game_play_preference`
  return fetch({
    method: 'post',
    url: url,
    data: getFormDataBySearch({
      app_pkgs: JSON.stringify(appPkgs),
      country,
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    }),
    noValidate: true,
  })
}

/**
 * Fetch suggested apps
 * @returns Promise
 */
export const getSuggestedApps = () => {
  const url = `${window.__HOST_CONFIG__.baseHost}/launcher/get_suggested_apps`
  return fetch({
    method: 'post',
    url: url,
    data: getFormDataBySearch({
      width: 300,
      launcher_version: window.__CLIENT_CONFIG__.commonConfig.bsxversion,
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    }),
    noValidate: true,
  })
}
