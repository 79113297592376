import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Virtual } from 'swiper'
import { useDebounce, useSize } from 'ahooks'
import classNames from 'classnames'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import CommonBannerWrap from "@/components/banner/CommanBannerWrap"
import BannerOne from '@/components/banner/bannerOne/Index'
import { onGameClickAction } from '@/actions/commonActions'
import SkeletonGameList from '@/skeletons/GameList'
import GameCarouselTwo from '@/components/CarouselSection/GameCarouselSectionForHomeBanner'
import { STAT_CLOUD_GAME_PAGE_BANNER } from '@/tool/constant'
import getScrollParent from '@/tool/getScrollParent'

import styles from './banner.module.scss'

const CLOUD_GAME_BANNER_ACTIVE_INDEX_STORAGE_KEY = 'cloudGameBannerActiveIndex'
const CloudGameBanner = ({ gridCount, className }) => {
  const dispatch = useDispatch()
  const [isInitPage, setIsInitPage] = useState(true)
  const cloudApps = useSelector((state) => state.cloud.cloudGames) || []
  const loadingStore = useSelector((state) => state.loading)
  const [bannerGame, setBannerGame] = useState(null)
  const [activeIndex, setActiveIndex] = useState(
    +window.sessionStorage.getItem(CLOUD_GAME_BANNER_ACTIVE_INDEX_STORAGE_KEY) || 0
  )

  const viewportSize = useSize(document.documentElement)

  const debounceSize = useDebounce(viewportSize, { wait: 200 })
  // 页面初始化
  useEffect(() => {
    if (cloudApps.length) {
      setBannerGame(cloudApps[activeIndex])
    }
  }, [cloudApps, activeIndex])

  // 切换banner大图
  useEffect(() => {
    if (bannerGame) {
      let top = 0
      const dom = document.getElementById('homeBannerApps')
      const homeContainerWrap = getScrollParent(dom)
      if (homeContainerWrap) {
        top = window.getRealMeasurement(homeContainerWrap.scrollTop)
      }
      qwebApi({
        event: 'changeBannerBackgroundImage',
        data: {
          url: bannerGame.media.desktop?.wallpaper,
          video: bannerGame.video_url,
          playCount: 1,
          sleep: 500,
          tileRatio: 0.6,
          type: 'position',
          top,
        },
      })
    }
  }, [bannerGame])

  useEffect(() => {
    try {
      const dom = document.getElementById('homeBannerApps')
      const homeContainerWrap = getScrollParent(dom)
      if (dom && homeContainerWrap) {
        const domRect = dom.getBoundingClientRect()
        qwebApi({
          event: 'onHomeBannerSizeChange',
          data: {
            left: window.getRealMeasurement(domRect.x + 23 + homeContainerWrap.scrollLeft),
            top: window.getRealMeasurement(
              domRect.y + 50 + domRect.height / 3 + homeContainerWrap.scrollTop
            ),
          },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }, [debounceSize.width, isInitPage])

  // 打开游戏
  const handleOnClick =
    ({ evt, gameType, isInstalled, app, actionCode, redirectCode }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        dispatch,
        gameType,
        bannerLocation: STAT_CLOUD_GAME_PAGE_BANNER,
        actionCode,
        redirectCode,
      })
    }
  // 鼠标移入事件
  const onHoverEnter = useCallback(
    (item, index) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )
  return <CommonBannerWrap className={classNames([styles.container, className])}>
    <BannerOne buttonMode="round" banner={bannerGame} loading={loadingStore.cloudGameLoading} handleOnClick={handleOnClick} className={styles.bannerInfo} />
    {cloudApps.length ? (
      <ErrorFallback>
        <GameCarouselTwo
          className={styles.bannerList}
          carouselKeyId="homeBannerGames"
          list={cloudApps}
          imgPlaceholderClass="ratio-1-1"
          bannerLocation={STAT_CLOUD_GAME_PAGE_BANNER}
          showCloudBtn
          showPlayBtn
          showInstalledState
          showDetailIcon={false}
          slidesPerView={gridCount + 1}
          onHoverEnter={onHoverEnter}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          slideConfig={{
            modules: [Virtual],
            virtual: true,
            onActiveIndexChange: (swiper) => {
              if (!isInitPage) {
                setActiveIndex(swiper.activeIndex)
              }
            },
            onAfterInit: (swiper) => {
              setTimeout(() => {
                swiper.slideTo(activeIndex, 0)
                setIsInitPage(false)
              }, 100)
            },
          }}
        />
      </ErrorFallback>
    ) : (
      <SkeletonGameList
        className={styles.skeletonContainer}
        column={gridCount + 1}
        showHeader={false}
        gameItemProps={{ showSubTitle: false }}
        enable={loadingStore.cloudGameLoading}
      />
    )}
  </CommonBannerWrap>
}

export default CloudGameBanner
