import classNames from 'classnames'
import React from 'react'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import Heading from '@/components/common/Heading/Heading'

import './header.scss'

const Header = ({
  title,
  titleId,
  titleClass = null,
  renderRight = null,
  renderLeft = null,
  backIcon = false,
  type = 'section',
  onClick,
}) => {
  return (
    <div className={classNames(['skeleton_header', titleClass, `${type}Heading`, 'heading'])}>
      <div className="skeleton_headerLeft" onClick={onClick}>
        {backIcon && <SvgIcon icon="arrow-left" className={['pointer', 'margin-right-10']} />}
        {(title || titleId) && <Heading title={title} titleId={titleId} />}
        {renderLeft}
      </div>
      <div className="skeleton_headerRight">{renderRight}</div>
    </div>
  )
}

export default Header
