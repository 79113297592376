/**
 *
 */
import React, { useMemo, useRef } from 'react'
import classnames from 'classnames'
import Scrollload from '@/components/scrollload/Scrollload'
import useScrollBarActive from '@/hook/useScrollBarActive'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'

import './index.scss'
const GridLayout = ({
  className,
  contentInnerClass,
  columns,
  list,
  customTitleRender,
  children,
  keyId,
  suffix = null,
  prefix = null,
  numberOfItems = columns,
  overscrollBehavior = 'auto'
}) => {
  const scrollContainerRef = useRef(null)
  // 标题栏
  const titleBarHeaderJsx = useMemo(() => {
    let titleJSX = null
    if (customTitleRender) {
      titleJSX = typeof customTitleRender === 'function' ? customTitleRender() : customTitleRender
    }
    return titleJSX
  }, [customTitleRender])

  // 列表样式
  const contentInnerStyl = useMemo(() => {
    return {
      gridTemplateColumns: `repeat(${columns}, minmax(10px, 1fr))`,
    }
  }, [columns])
  useScrollBarActive(scrollContainerRef)
  return (
    <div className={classnames(['gridLayout-container', className])}>
      <div className="gridLayout-header">{titleBarHeaderJsx}</div>
      <div className="gridLayout-content" ref={scrollContainerRef} style={{ overscrollBehavior }}>
        <div className="gridLayout-prefix">{prefix && prefix(list)}</div>
        <div
          className={classnames(['gridLayout-contentInner', contentInnerClass])}
          style={contentInnerStyl}
        >
          <ErrorFallback>
            <Scrollload
              list={list}
              scrollContainer={() => scrollContainerRef.current}
              numberOfItems={numberOfItems}
              render={(scrollList) => {
                return scrollList.map((item, index) => {
                  return (
                    <div
                      className="gridLayout-contentItem"
                      key={item[keyId] + '_' + index || index}
                    >
                      {children(item, index)}
                    </div>
                  )
                })
              }}
            />
          </ErrorFallback>
        </div>
        <div className="gridLayout-suffix">{suffix && suffix(list)}</div>
      </div>
    </div>
  )
}

export default React.memo(GridLayout)
