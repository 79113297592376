import React, { useMemo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useHover } from 'ahooks'
import AspectImage from '@/components/common/Image/AspectImage'
import MarqueeText from '@/components/common/marqueeText/Index'
import { installOrOpenPCGame } from '@/actions/commonActions'
import Button from '@/components/common/Button/Button'
import { STAT_GOOGLE_SEARCH_PAGE_SEARCH_BAR } from '@/tool/constant'
import { onGameClickAction } from '@/actions/commonActions'
import useAppLaunchParams from '@/hook/useAppLaunchParams'
import { getButtonText } from '@/tool/buttonText'
import useSupportCloudWrap from '@/hook/useSupportCloudWrap'
import style from './resultItem.module.scss'
import classNames from 'classnames'
const ResultItem = ({ app, keyword, as = 'div' }) => {
  const intl = useIntl()
  const containerRef = useRef(null)
  const installedApps = useSelector((state) => state.app.installedApps)
  const oneButtonConfig = useSelector((state) => state.user.clientUserConfig.one_button_config)
  const _app = useSupportCloudWrap(app)
  const localPerference = useSelector((state) => state.app.localPerference[_app.package_name])
  const isInstalled = installedApps.includes(_app.package_name)

  /**
   * On click app event
   */
  const handleOnClick = useCallback(
    ({ evt, gameType, actionCode, redirectCode }) => {
      onGameClickAction({
        app: _app,
        isInstalled,
        evt,
        gameType,
        bannerLocation: STAT_GOOGLE_SEARCH_PAGE_SEARCH_BAR,
        actionCode,
        redirectCode,
      })
    },
    [_app, isInstalled]
  )

  const isHovering = useHover(containerRef)
  const isInstalledPcGame = false
  // Install or open pc game
  const onInstallOrOpenPcGame = useCallback(
    ({ evt, url, packageName }) => {
      evt.preventDefault()
      evt.stopPropagation()
      installOrOpenPCGame({
        url,
        packageName,
        location: STAT_GOOGLE_SEARCH_PAGE_SEARCH_BAR,
        isInstalled: isInstalledPcGame,
      })
    },
    [isInstalledPcGame]
  )

  // App actions list
  const buttonsJSX = useMemo(() => {
    const buttonJSX = []
    const nonAndroidGameDatas = _app?.non_android_game_data || []
    let pcGameButton = null
    if (nonAndroidGameDatas.length) {
      const firstData = nonAndroidGameDatas[0]
      pcGameButton = (
        <Button
          key="pcButton"
          type="primaryChange"
          icon="window"
          size="mini"
          onClick={(evt) =>
            onInstallOrOpenPcGame({
              evt,
              url: firstData.download_url,
              packageName: _app.package_name,
            })
          }
        />
      )
    }
    if (oneButtonConfig === 3) {
      if (_app.showPlayBtn) {
        const { actionText, actionCode } = getButtonText(intl, {
          isInstalled,
          app: _app,
          platforms: 'local',
          oneButtonConfig,
        })
        buttonJSX.push(
          <Button
            size="mini"
            key="playOnBs"
            type="successChange"
            icon="bluestacks"
            className={style.actionButton}
            onClick={(evt) =>
              handleOnClick({ evt, gameType: 'simulator', actionCode, redirectCode: 'AppLauncher' })
            }
          >
            {isHovering ? <MarqueeText>{actionText}</MarqueeText> : <font>{actionText}</font>}
          </Button>
        )
      }
      buttonJSX.push(pcGameButton)
      // User selector mode
      if (_app.showCloudBtn) {
        const { actionCode } = getButtonText(intl, {
          app: _app,
          platforms: 'cloud',
          oneButtonConfig,
        })
        buttonJSX.push(
          <Button
            size="mini"
            key={actionCode}
            type="pinkChange"
            icon="nowgg"
            className={style.actionButton}
            onClick={(evt) => {
              handleOnClick({
                evt,
                gameType: 'cloud',
                actionCode,
                redirectCode: 'AppLauncher',
              })
            }}
          />
        )
      }
    } else {
      const { actionText, actionCode } = getButtonText(intl, {
        isInstalled,
        app: _app,
        platforms: localPerference,
        oneButtonConfig: 3,
      })
      const isPlayOnCloud = actionCode === 'playOnCloud'
      const _actionText = isPlayOnCloud ? intl.formatMessage({ id: 'playInBrowser' }) : actionText

      buttonJSX.push(
        <Button
          size="mini"
          key="installButton"
          type={isPlayOnCloud ? 'pinkChange' : 'successChange'}
          className={style.actionButton}
          icon={isPlayOnCloud ? 'nowgg' : 'bluestacks'}
          onClick={(evt) => {
            handleOnClick({ evt, gameType: 'simulator', actionCode, redirectCode: 'AppLauncher' })
          }}
        >
          {isHovering ? <MarqueeText>{_actionText}</MarqueeText> : <font>{_actionText}</font>}
        </Button>
      )
      buttonJSX.push(pcGameButton)
    }
    return buttonJSX
  }, [
    _app,
    isHovering,
    handleOnClick,
    intl,
    isInstalled,
    localPerference,
    oneButtonConfig,
    onInstallOrOpenPcGame,
    isInstalledPcGame,
  ])

  // Click item event(direct to app detail)
  const { gameType, actionCode } = useAppLaunchParams(_app)
  const onItemClick = useCallback(
    (evt) => {
      handleOnClick({ evt, gameType, actionCode, redirectCode: 'AppDetail' })
    },
    [gameType, actionCode, handleOnClick]
  )
  const AsDOm = as
  const gameNameHtml = keyword
    ? _app.game_name.replace(new RegExp(keyword, 'gi'), (match) => {
        return `<span class="${style.matchString}">${match}</span>`
      })
    : _app.game_name
  return (
    <AsDOm
      className={classNames([style.container, { pointer: !_app.steam_supported }])}
      ref={containerRef}
      onClick={(evt) => !_app.steam_supported && onItemClick(evt)}
    >
      <div className={style.left}>
        <AspectImage aspectClass="ratio-1-1" src={_app.icon_url} />
      </div>
      <div className={style.right}>
        <div className={style.title} dangerouslySetInnerHTML={{ __html: gameNameHtml }}></div>
        <div className={style.actions}>{buttonsJSX}</div>
      </div>
    </AsDOm>
  )
}

export default ResultItem
