import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

export const getSupportCloudApp = (app = {}, cloudApps) => {
  const _app = cloudApps.find((game) => game.package_name === app.package_name)
  if (_app) {
    return {
      ...app,
      cloud_app_url: _app.cloud_app_url,
      cloud_supported: _app.cloud_supported,
      showCloudBtn: _app.showCloudBtn,
    }
  }
  return app
}

const Hook = (app) => {
  const cloudGames = useSelector((state) => state.cloud.cloudGames)
  const [wrapApp, setWrapApp] = useState(app)
  useEffect(() => {
    setWrapApp(getSupportCloudApp(app, cloudGames))
  }, [cloudGames, app])
  return wrapApp
}

export default Hook
