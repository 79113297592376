/**
 * Fetch game list by topicId(will cached by topicId in redux)
 */
import React, { useMemo, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@/components/common/Button/Button'
import GamePageHeader from '@/components/common/Heading/GamePageHeader'
import GameGridLayout from '@/components/layout/gameGridLayout/Index'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import { setTopicApps } from '@/reducers/app/actions'
import { switchLoader } from '@/reducers/system/actions'
import { STAT_APPS_PAGE_TYPEFOR_TOPIC_FROM_ANDROIDGAMES } from '@/tool/constant'
import { getTopicInfo } from '@/apis/apps'
import SkeletonAppList from '@/skeletons/AppList'
import { useGridBaseCount } from '@/hook/useViewport'
import { qwebApi } from '@/apis/qwebApi'
import style from './index.module.scss'
const ByTopicApps = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const urlParams = new URLSearchParams(location.search)
  const topicId = urlParams.get('topicId')
  const topics = useSelector((state) => state.app.topics)
  // Get topic games from redux
  const topicInfo = useSelector((state) => state.app.topicAppsMap[topicId])
  const apps = topicInfo?.apps || []

  const gridCount = useGridBaseCount()
  // Active topic item
  const activeTopic = useMemo(() => {
    return topics.find((topic) => topic.id === topicId)
  }, [topics, topicId])

  // Fetch game list
  const { refetch, isSuccess, isFetching, isFetched } = useQuery(
    ['getTopicInfo', topicId],
    () => getTopicInfo({ topic_id: topicId }),
    {
      enabled: !!topicId && apps.length === 0,
      onSuccess: (data) => {
        dispatch(setTopicApps({ data: data.result || {}, topicId }))
      },
    }
  )

  // loading事件
  useEffect(() => {
    !process.env['REACT_APP_SHOW_SKELETON'] && dispatch(switchLoader(isFetching))
  }, [isFetching, dispatch])

  const headerJSX = (
    <GamePageHeader
      onBack={() => {
        const state = location.state
        // 返回判断客户端行为
        if (state?.returnCode) {
          qwebApi({
            event: 'onReturnToClient',
            data: {
              returnCode: state?.returnCode
            }
          })
        }
        navigate(-1)
      }}
      title={
        <FormattedMessage
          id="subHeadingCategory"
          values={{ category: activeTopic?.topic_name || topicId }}
        />
      }
      count={apps.length}
    />
  )

  if (apps.length === 0 && isFetching) {
    return (
      <SkeletonAppList
        className={['breakScreen', 'margin-bottom-30']}
        imgPlaceholderClass="ratio-1-1"
        headerRender={() => headerJSX}
        column={gridCount}
        row={2}
        enable={isFetching}
      />
    )
  }

  return (
    <GameGridLayout
      overscrollBehavior='none'
      gameItemProps={{
        imgPlaceholderClass: 'ratio-1-1',
        showPlayBtn: true,
        showCloudBtn: true,
        showInstalledState: true,
        showCsIcon: true,
        bannerLocation: STAT_APPS_PAGE_TYPEFOR_TOPIC_FROM_ANDROIDGAMES,
      }}
      list={apps}
      contentInnerClass={['breakScreen', 'margin-bottom-30']}
      columns={gridCount}
      customTitleRender={() => {
        return <div className="breakScreen">{headerJSX}</div>
      }}
      prefix={(list) => {
        if (list.length === 0 && isFetched) {
          return (
            <CommonHelpTip
              textTitle={isSuccess ? 'noFoundBySearch' : 'noInternetConnect'}
              imgSrc={isSuccess ? 'noResults' : 'noNetwork'}
              localSvg
              extra={() => (
                <Button type="primary" onClick={refetch} className={style.refreshButton}>
                  <FormattedMessage id="refresh" />
                </Button>
              )}
            />
          )
        }
      }}
    />
  )
}

export default React.memo(ByTopicApps)
