import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { findDOMNode } from 'react-dom'
import classnames from 'classnames'
import './index.scss'
const Image = ({
  className,
  src,
  onClick,
  alt = src,
  onLoad,
  onError,
  // 图片是否lazy-load-
  lazy = true,
  enableObserve = true,
  // -----lazy为true有效---------------------------
  // 单距离多少进行加载，类似margin语法
  rootMargin = '500px',
  // offset相对容器（最好设置成图片所在滚动容器）
  root = null,
  // ----------------------------------------------
  children,
  objectFit = 'contain',
  position = 'center',
}) => {
  const domRef = useRef(null)
  const containerRef = useRef(null)
  const [isError, setIsError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [containerClass, setContainerClass] = useState([])

  // 加载失败回调
  const _onLoadError = useCallback(
    (e) => {
      setIsLoaded(false)
      setIsError(true)
      if (onError) onError(e)
    },
    [onError]
  )

  // 加载成功回调
  const _onLoadSuccess = useCallback(
    (e) => {
      const container = containerRef.current
      const { naturalHeight, naturalWidth } = e.target
      let containerWidth = 0
      let containerHeight = 0
      if (container) {
        containerWidth = container.clientWidth
        containerHeight = container.clientHeight
      }
      let containerClassTemp = []
      // 图片大小小于容器大小时
      if (naturalHeight < containerHeight && naturalWidth < containerWidth) {
        containerClassTemp.push('includeSize')
      }
      if (naturalWidth / naturalHeight !== containerWidth / containerHeight) {
        containerClassTemp.push('ratioDifferent')
      }
      // 图片
      if (naturalHeight > naturalWidth) {
        containerClassTemp.push('heightGtWidth')
      } else if (naturalWidth > naturalHeight) {
        containerClassTemp.push('widthGtHeight')
      } else {
        containerClassTemp.push('squareSize')
      }
      // 容器
      if (containerHeight > containerWidth) {
        containerClassTemp.push('containerHeightGtWidth')
      } else if (containerWidth > containerHeight) {
        containerClassTemp.push('containerWidthGtHeight')
      } else {
        containerClassTemp.push('containerSquareSize')
      }
      setContainerClass(containerClassTemp)
      setIsLoaded(true)
      setIsError(false)
      if (onLoad) {
        onLoad(e)
      }
    },
    [onLoad]
  )
  // show placeholder?
  const showPlaceholder = !src || !isLoaded || isError
  const srcProps = useMemo(() => {
    if (lazy) {
      return { 'data-src': src }
    } else {
      return { src }
    }
  }, [lazy, src])
  // Observer image dom
  useEffect(() => {
    let observer
    if (enableObserve && lazy && domRef.current) {
      function callback(entries) {
        for (let entry of entries) {
          if (entry.intersectionRatio > 0 || entry.isIntersecting) {
            const srcUrl = entry.target.dataset['src']
            if (srcUrl) {
              entry.target.src = srcUrl
            }
            observer.unobserve(entry.target)
          }
        }
      }
      observer = new IntersectionObserver(callback, {
        root:
          typeof root === 'function' ? root() : root?.current ? findDOMNode(root.current) : root,
        rootMargin: typeof rootMargin === 'function' ? rootMargin() : rootMargin,
      })
      observer.observe(domRef.current)
    }
    return () => {
      observer && observer.disconnect()
    }
  }, [enableObserve, lazy, src, rootMargin, root])
  return (
    <div
      ref={containerRef}
      onClick={onClick}
      className={classnames([
        'imageContainer',
        position,
        { defaultImge: showPlaceholder },
        containerClass,
        className,
      ])}
    >
      <img
        ref={domRef}
        className="imageImg imageContainerChild"
        alt={alt}
        onError={(e) => _onLoadError(e)}
        onLoad={(e) => _onLoadSuccess(e)}
        {...srcProps}
        style={{ objectFit }}
      />
      {children}
    </div>
  )
}

export default React.memo(Image)
