import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import CategoryItem from './Item'
import styles from './index.module.scss'
import SkeletionsCloudGameCategory from '@/skeletons/cloudGameCategory/Index'
const Categorys = ({ className }) => {
  const categoryMap = useSelector((state) => state.cloud.categoryMap)
  const loadingStore = useSelector((state) => state.loading)
  const categorys = Object.keys(categoryMap).map(category => ({
    label: category,
    icon_url: categoryMap[category][0].icon_url
  }))

  return <div className={classNames([styles.container, className])}>
    <div className={styles.header}>
      <FormattedMessage id='discoverYourFavoriteGamingGenre' />
    </div>
    <div className={styles.content}>
      {categorys.length > 0 ? categorys.map(category => {
        return <CategoryItem key={category.label} category={category} />
      }) : <SkeletionsCloudGameCategory count={10} enable={loadingStore.cloudGameLoading} />}
    </div>

  </div>
}

export default Categorys
