import React from 'react'
import { useNavigate } from 'react-router-dom'
import style from './item.module.scss'
const CollectionItem = ({ topic, styleObj }) => {
  const navigate = useNavigate()

  const onItemClick = (topic) => {
    navigate({
      pathname: '/byTopicApps',
      search: `topicId=${topic.id}`,
    })
  }
  return (
    <span className={style.container} style={styleObj} onClick={() => onItemClick(topic)}>
      {topic.topic_name}
    </span>
  )
}

export default CollectionItem
