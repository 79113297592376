/**
 * Game carousel module
 */

import React, { useCallback, useMemo } from 'react'
import Carousel from '@/components/Carousel/Index'
import BaseHeader from '@/components/common/Heading/BaseHeader'
import Button from '@/components/common/Button/Button'
import Link from '@/components/common/router/Link'
import RefreshIcon from '@/components/Carousel/components/RefreshIcon'
import Heading from '@/components/common/Heading/Heading'
import { createGroup } from '@/tool'
const GameCarouselSection = ({
  title,
  titleId,
  className,
  linkTo,
  pagination,
  onRefresh,
  list,
  linkToText = 'viewAll',
  titleClass,
  // slider组件props
  slideConfig,
  carouselKeyId,
  slidesPerView,
  slidesPerGroup,

  children,
  groupNumber = 0,
  isGroup = true,
  gap,
}) => {
  const sliderList = useMemo(() => {
    if (isGroup) {
      return createGroup(groupNumber || 1, list)
    }
    return list
  }, [list, groupNumber, isGroup])

  // 数据是否大于一页
  const upOnePage = useMemo(() => {
    if (isGroup) {
      if (list.length > groupNumber) {
        return true
      }
      return false
    }
    const compareLength = slidesPerView === 'auto' ? 3 : slidesPerView
    return list.length > compareLength
  }, [list.length, groupNumber, isGroup, slidesPerView])

  const renderHeader = useCallback(
    ({ swiperSwitch }) => {
      const renderHeaderLeft = (
        <div className="game-carousel-section-headerLeftWrap">
          <Heading title={title} titleId={titleId} />
          {onRefresh && upOnePage && <RefreshIcon onRefresh={onRefresh} />}
        </div>
      )
      const renderHeaderRight = (
        <div className="game-carousel-section-headerRightWrap">
          {pagination && <div style={{display:upOnePage?'unset':'none'}}>{swiperSwitch}</div>}
          {linkTo && upOnePage ? (
            <Link key="link" to={linkTo}>
              <Button size="small" type="primaryChange" titleId={linkToText} />
            </Link>
          ) : null}
        </div>
      )
      return (
        <BaseHeader
          renderLeft={renderHeaderLeft}
          renderRight={renderHeaderRight}
          className={titleClass}
        />
      )
    },
    [linkTo, upOnePage, pagination, title, titleId, onRefresh, linkToText, titleClass]
  )
  if (sliderList.length === 0) {
    return null
  }
  return (
    <Carousel
      carouselKeyId={carouselKeyId}
      list={sliderList}
      className={['game-carousel-section', className]}
      slideConfig={slideConfig}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerGroup}
      renderHeader={renderHeader}
      gap={gap}
    >
      {children}
    </Carousel>
  )
}

export default GameCarouselSection
