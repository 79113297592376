import React, { useImperativeHandle, useState, useRef, useCallback, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useIntl } from 'react-intl'
import ResultItem from './ResultItem'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import { getSearchResultTransformer } from '@/transformers/transformerApi'
import Loading from '@/images/search/loading.svg'
import GooglePlayButton from '@/components/common/Button/googlePlay/Index'
import style from './resultPanel.module.scss'
import { qwebApi } from '@/apis/qwebApi'
import stats, { TABLE_TYPE, STATS_POINT } from '@/tool/stats'
import { STAT_GOOGLE_SEARCH_PAGE_SEARCH_BAR } from '@/tool/constant'

const ResultPanel = ({ disabled = false, query, onChange, onJudgeToSearchPage }, ref) => {
  const intl = useIntl()
  const containerRef = useRef(null)
  const [list, setList] = useState([])

  // Fetch search result
  const postQueryResult = useMutation((query) => getSearchResultTransformer(query))

  // Listen list change
  useEffect(() => {
    onChange && onChange()
  }, [postQueryResult.isLoading, onChange])

  // Trigger to fetch search result
  const onStartQuery = useCallback(
    async (word) => {
      const result = await postQueryResult.mutateAsync(word)
      setList(result)
    },
    [postQueryResult]
  )

  // Clicked on google play button
  const onSearchInGooglePlayClick = useCallback(() => {
    qwebApi({ event: 'onSearchInGooglePlay', data: { query } })
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK_V2, {
      event_type: STATS_POINT.BSX_SEARCH_IN_GP_CLICKED,
      query,
      banner_location: STAT_GOOGLE_SEARCH_PAGE_SEARCH_BAR,
    })
  }, [query])

  // Clicked on view all games button
  const onViewAllGamesClick = useCallback(() => {
    onJudgeToSearchPage(query)
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK_V2, {
      event_type: STATS_POINT.BSX_GPPAGE_VIEW_ALL_GAMES,
      query,
      banner_location: STAT_GOOGLE_SEARCH_PAGE_SEARCH_BAR,
    })
  }, [onJudgeToSearchPage, query])

  useImperativeHandle(ref, () => {
    return {
      startQuery: onStartQuery,
      getClientHeight: () => containerRef.current?.clientHeight || 0,
    }
  })

  if (disabled) {
    return null
  }
  return (
    <div className={style.container} ref={containerRef}>
      {postQueryResult.isLoading ? (<>
        <div className={style.loadingContainer}>
          {/* <SvgIcon size={1} icon="loading" /> */}
          <img className={style.loadingIcon} src={Loading} alt="Search loading" />
          <span>{intl.formatMessage({ id: 'searching' })}</span>
        </div>
        {/* <GooglePlayButton onClick={onSearchInGooglePlayClick} className={style.searchButton} /> */}
        </>
      ) : (
        <>
          {list.length === 0 && (
            <div className={style.emptyContainer}>
              <SvgIcon className={style.emptyContainerIcon} size={1} icon="search-no-results" />
              <span className={style.emptyContainerText}>{intl.formatMessage({ id: 'noResultsFound' })}</span>
            </div>
          )}
          <ul className={style.content}>
            {list.map((app) => {
              return <ResultItem as="li" key={app.package_name} app={app} keyword={query} />
            })}
          </ul>
          {list.length > 0 ? (
            <div className={style.footer}>
              <div className={style.footerAlert} onClick={onViewAllGamesClick}>
                <span>{intl.formatMessage({ id: 'viewAllGamesWithQuery' }, { query })}</span>
                <SvgIcon className={style.footerIcon} size={1} icon="arrow-line-right" />
              </div>
            </div>
          ) : (
            <GooglePlayButton onClick={onSearchInGooglePlayClick} className={style.searchButton} />
          )}
        </>
      )}
    </div>
  )
}

export default React.forwardRef(ResultPanel)
