import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useLocation, useNavigationType, useOutlet } from 'react-router-dom'
import Utils from '@/Utils'
import { qwebApi } from '@/apis/qwebApi'
import './index.css'
import { useSelector } from 'react-redux'
import Setup from '@/components/setup/Index'

const ANIMATION_MAP = {
  PUSH: 'translateX',
  POP: 'reTranslateX',
  REPLACE: 'reTranslateX',
}
function Animate() {
  const location = useLocation()
  const currentOutlet = useOutlet()
  const navigateType = useNavigationType()
  const showOfflinePage = useSelector((state) => state.system.showOfflinePage)

  return (
    <>
      <Setup />
      {showOfflinePage ? null : <TransitionGroup
        component={null}
        childFactory={(child) => {
          const animation = Utils.getParamByName('animation')
          let classNames = ANIMATION_MAP[navigateType]
          if (animation && navigateType === 'PUSH') {
            classNames = animation
          }
          return React.cloneElement(child, {
            classNames,
          })
        }}
      >
        <CSSTransition
          timeout={300}
          key={location.pathname}
          onExit={() => {
            qwebApi({ event: 'changeBannerBackgroundImage' })
          }}
        >
          {() => {
            return <div className="contentInner">
              {currentOutlet}
            </div>
          }}
        </CSSTransition>
      </TransitionGroup>
      }
    </>
  )
}

export default Animate
