import React from 'react'
import { useNavigate } from 'react-router'
import { FormattedMessage } from 'react-intl'
import AspectImage from '@/components/common/Image/AspectImage'
import styles from './index.module.scss'
const CategoryItem = ({ category }) => {
  const navigate = useNavigate()
  const onItemClick = () => {
    navigate({
      pathname: '/cloudGameByCategory',
      search: `category=${category.label}&title=${category.label}&from=cloudGame`
    })
  }
  return <div className={styles.categoryItem} onClick={onItemClick}>
    <div className={styles.logo}>
      <AspectImage aspectClass="ratio-1-1" src={category.icon_url} />
    </div>
    <span className={styles.title}>
      <FormattedMessage
        id="subHeadingCategory"
        values={{ category: category.label }}
      />
    </span>
  </div>
}

export default CategoryItem
