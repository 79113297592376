import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import GameGridLayout from '@/components/layout/gameGridLayout/Index'
import GameCarouselSection from '@/components/CarouselSection/GameCarouselSection'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import CollectionCarouselSection from '@/components/CarouselSection/CollectionCarouselSection'
import SectionCarousel from '@/components/AdvertisementSection/SectionCarousel'
import SkeletonGameList from '@/skeletons/GameList'
import SkeletonLargeCarousel from '@/skeletons/LargeCarousel'

import {
  // STAT_GAMECENTER_PAGE_NEWANDTRENDING,
  STAT_GAMECENTER_PAGE_FEATURE,
  STAT_HOME_PAGE_POPULARGAMES
} from '@/tool/constant'
const GameCenterContainer = ({ className, gridCount }, ref) => {
  const countryCode = useSelector((state) => state.system.countryCode)
  const loadingStore = useSelector((state) => state.loading)

  // 置顶游戏（selected_game）
  // const topApps = useSelector((state) => state.app.topApps)

  // 火热游戏（hot_list）
  const hotApps = useSelector((state) => state.app.hotApps)
  // collection
  const topics = useSelector((state) => state.app.topics)
  // Feature app
  const featureApps = useSelector((state) => state.app.featureApps)



  return (
    <div className={classNames(['game-center-container', className])} ref={ref}>
      {/* Browser By Collection */}
      <ErrorFallback>
        <CollectionCarouselSection
          titleId="browseByCollection"
          className="margin-bottom-30"
          isLoading={loadingStore.gameCenterHomeLoading}
          columns={gridCount}
          list={topics}
        />
      </ErrorFallback>
      {/* Our New Favourites */}
      {featureApps.length ? (
        <ErrorFallback>
          <SectionCarousel
            list={featureApps}
            bannerLocation={STAT_GAMECENTER_PAGE_FEATURE}
            showInstalledState
            className="margin-top-60 margin-bottom-30"
          />
        </ErrorFallback>
      ) : (
        <SkeletonLargeCarousel
          actionCount={1}
          titleId="featuredGame"
          imgPlaceholderClass="ratio-131-50"
          enable={loadingStore.cpiAppsForQuestLoading}
        />
      )}
      {/* Popular Games in your region */}
      {hotApps.length ? (
        <ErrorFallback>
          <GameCarouselSection
            carouselKeyId="homeHotGames"
            titleClass="spaceBetween"
            titleId="androidGamesLike"
            list={hotApps}
            // className="margin-bottom-30"
            linkTo={{
              pathname: '/apps',
              search: 'type=hotApps&titleId=androidGamesLike&from=home',
            }}
            showPlayBtn
            showCloudBtn
            bannerLocation={STAT_HOME_PAGE_POPULARGAMES}
            imgPlaceholderClass="ratio-3-4"
            imageUrlGet={(game) => (countryCode === 'JP' ? game.icon_url : game.banner_url_v)}
            showInstalledState
            slidesPerView={1}
            groupNumber={(gridCount - 1) * 2}
            renderItem={({ slide, itemProps, groupNumber }) => {
              return (
                <GameGridLayout
                  gameItemProps={itemProps}
                  list={slide}
                  columns={gridCount - 1}
                  numberOfItems={groupNumber}
                />
              )
            }}
          />
        </ErrorFallback>
      ) : (
        <SkeletonGameList
          imgPlaceholderClass="ratio-3-4"
          titleId="androidGamesLike"
          column={gridCount - 1}
          enable={loadingStore.gameCenterHomeLoading}
        />
      )}
      {/* New and Trending */}
      {/* {hotApps.length ? (
        <ErrorFallback>
          <GameCarouselSection
            titleId="topGames"
            carouselKeyId="popularGames"
            bannerLocation={STAT_GAMECENTER_PAGE_NEWANDTRENDING}
            list={hotApps}
            className="gameList"
            linkTo={{
              pathname: '/apps',
              search: 'type=hotApps&from=gameCenter&titleId=popularGames',
            }}
            imgPlaceholderClass="ratio-1-1"
            showPlayBtn
            showCloudBtn
            showInstalledState
            slidesPerView={1}
            groupNumber={gridCount * 3}
            renderItem={({ slide, itemProps, groupNumber }) => {
              return (
                <GameGridLayout
                  gameItemProps={itemProps}
                  list={slide}
                  columns={gridCount}
                  numberOfItems={groupNumber}
                />
              )
            }}
          />
        </ErrorFallback>
      ) : (
        <SkeletonGameList
          imgPlaceholderClass="ratio-1-1"
          titleId="popularGames"
          column={gridCount}
          row={3}
          enable={loadingStore.gameCenterHomeLoading}
        />
      )} */}
    </div>
  )
}

export default React.forwardRef(GameCenterContainer)
