import React, { useRef } from 'react'
import classNames from 'classnames'
import { useSwiper } from 'swiper/react'
import Image from '@/components/common/Image/Image'
import Video from '@/components/common/Video/Index'
import { VideoType } from '@/components/common/Video/Index'
import './index.scss'

const Gallery = ({
  className,
  onClick,
  type,
  src,
  posturl
}) => {
  const galleryRef = useRef(null)
  const swiper = useSwiper()
  const onLoad = () => {
    galleryRef.current.style.width = 'unset'
    setTimeout(() => swiper.update(), 300)
  }
  return (
    <div
      className={classNames(['gallery-container', className])}
      onClick={onClick}
      ref={galleryRef}
    >
      {type === 1 ? <Video src={src} posturl={posturl} type={VideoType.youtube} onLoad={onLoad} /> : <Image src={src} onLoad={onLoad} />}
    </div>
  )
}

export default React.memo(Gallery)
