import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { qwebApi } from '@/apis/qwebApi'
import { getSupportCloudApp } from '@/hook/useSupportCloudWrap'

const Hook = () => {
  const cloudGames = useSelector((state) => state.cloud.cloudGames)
  const suggestedApps = useSelector((state) => state.app.suggestedApps)
  const default_search_term = useSelector((state) => state.app.default_search_term)

  useEffect(() => {
    if (suggestedApps.length) {
      qwebApi({
        event: 'pushPromotesApps',
        data: {
          apps: suggestedApps.map((app) => getSupportCloudApp(app, cloudGames)),
          default_search_term: default_search_term,
        },
      })
    }
  }, [cloudGames, suggestedApps, default_search_term])
}

export default Hook
